define('modules/cookienotice/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.cookie"], function(module,config,$,util,templates) {

    return new module({

        name: 'cookienotice',
        selectors: '.cookieNoticeWrap',
        routes: [],

        config: {
            cookieExpire: (1440*360) // Just short of one year default
        },

        initialize: function() {
            if(!$.cookie('usr-ckn')) {
                $(this.getSelectors()).html(templates.cookienoticebar());
                this.addButtonListeners();
            } else {
                $(this.getSelectors()).remove();
            }
        },

        methods: {
            addButtonListeners: function() {
                $(this.getSelectors()).find('.cookieNoticeAgree').on('click',$.proxy(function(e){
                    e.preventDefault();
                    var date = new Date();
                    date.setTime(date.getTime() + (this.getConfig().cookieExpire * 60 * 1000));
                    $.cookie('usr-ckn',true); // TODO - Set secure attribute as needed
                    $(this.getSelectors()).fadeOut(400,function(){
                        $(this).remove();
                    });
                },this));
            }
        }

    });

});
