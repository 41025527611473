define('app/router',['app/util','jquery','app/config','app/user','templates','jquery.livequery','jquery.hashchange'],function(util,$,config,user,templates){
    return {

        silentBack: false,

        routeHistory: [],
        routeQueue: [],
        activeComponents: [],
        originalTitle: '100&Change',
        aliases: null,
        missingPageEl: null,

        init: function() {
            this.setupMissingPage();
            this.applyRouterAliases();
            this.originalTitle = '100&Change';
            // Hash change listener - this should be the only instance of hashchange listener directly, across the whole app
            $(window).hashchange($.proxy(function(e){
                e.preventDefault();
                this.handleRampitInit();
                if(window.location.hash.replace('#','') == '404') { // The only instance of overriding router (reserved hash)
                    this.showMissingPage();
                } else {
                    if(!this.silentBack) {
                        this.clearMissingPage();
                        this.routeHistory.push(window.location.hash);
                        this.updateTitle(window.location.hash);
                        this.trackAnalytics(window.location.hash);
                    }
                    this.route(window.location.hash.substr(1)); // Remove the # from this point on
                }
            },this));
            window.getActiveComponents = $.proxy(function(){
                return this.activeComponents;
            },this);
        },

        // Called from the initializing app, adds app routes t
        addRoutes: function(routes,component) {
            $.each(routes,function(index,item){
                item.fn = (typeof item.fn == 'string' ? component[item.fn] : item.fn);  // Resolve any string methods
                item.fn = (item.fn ? $.proxy(item.fn,component) : $.noop); // Scope the route method to the component module
                item.module = component;
            });
            this.routeQueue = this.routeQueue.concat(routes);
            this.activeComponents[component.getName ? component.getName() : component.name] = component;
        },

        // Main method that handles the routes, and determines how to apply it
        // This method may not always apply the route, see applyRoute below
        route: function(hash) {
            if(this.checkAlias(hash)) {
                return;
            }
            if(this.silentBack) {
                this.silentBack = false;
                return;
            }
            var matchedRoutes = [];
            $.each(this.routeQueue,function(index,item){
                if(RegExp('^'+item.route+'$').test(hash)) {
                    if(!item.priority) {
                        item.priority = 1; // Set a default priority of 1 (highest)
                    }
                    if(item.extra) {
                        item.priority = 1000; // Push extra items to the end of the priority stack
                    }
                    matchedRoutes.push(item);
                }
            });
            if(matchedRoutes.length > 0) {
                matchedRoutes = matchedRoutes.sort(util.dynamicSort('priority'));
                matchedRoutes = matchedRoutes.filter(function(item,index){
                    return (index == 0 || item.extra);
                });
                matchedRoutes.forEach(function(item){
                    this.applyRoute(item,hash);
                }.bind(this));
            } else {
                this.handleMissingRoute(hash);
            }
        },

        // This method is called when a valid route is determined, and actually applies it to browser history
        applyRoute: function(route,hash) {

            var rtr = this;

            if(route && route.fn) {

                // Adds the route delay or defaults it to 50 for sanity purposes
                setTimeout($.proxy(function(){
                    //console.debug('Firing route: '+hash,route); // TODO - Update this to use pragmas for debug
                    var success = route.fn(hash);
                    if(success === false) {
                        $.proxy(function(){
                            this.handleMissingRoute(hash);
                        },rtr)();
                    }
                },route.fn),route.delay || 50);

                if(route.silent) {
                    // TODO - Look more into optimizing this method to handle different nav features better
                    if(this.routeHistory.length < 2) {
                        window.history.replaceState({},'Home','#home');
                        window.history.pushState({},route,'#'+hash);
                        this.silentBack = false;
                    } else {
                        this.silentBack = true;
                    }
                    window.history.back();
                }

                this.handleModal(route); // TODO - Should this be moved inside of route silent?

                this.applyModuleConfig(route.config);
            }
        },

        // This method is called on each route apply - and checks for modals
        // If modals are found to be open, and the route is a non-modal - it closes it
        handleModal: function(route) {
            if(!route.modal && !this.silentBack && this.routeHistory.length > 2) {
                if($('.modal').hasClass('in') && $('.modal.in').modal) {
                    $('.modal').modal('hide');
                }
            }
        },

        // Missing Page Methods (404)
        setupMissingPage: function() {
            if(templates['404']) {
                this.missingPageEl = $(templates['404']()); // TODO - Pass in any project specific config?
            }
        },

        showMissingPage: function() {
            // TODO - We should run this through the true navigation module (not the router), as these methods can and will change in the future if we use a module other than navigation.basic
            $('.pageContainer.active').removeClass('active').hide();
            $('.pageContainer.missing').addClass('active').show();
            $('.missingPageWrap').html($(this.missingPageEl)).show();
            $('.navbarWrap').addClass('missing-page-nav');
            util.scrollBodyToTop(500,true);
        },

        handleMissingRoute: function(hash) {
            // TODO - Record extra metadata into TrackJS for users hitting 404 pages
            if(this.missingPageEl) {
                this.stepBackSilent();

                setTimeout(function(){
                    window.location.hash = '404';
                },250);

            } else {
                console.warn('Missing route, and no 404 page present.',hash);
            }
        },

        clearMissingPage: function() {
            $('.pageContainer.missing').removeClass('active').hide();
            $('.missingPageWrap').empty().hide();
        },

        // Util Methods
        stepBack: function() {
            window.history.back();
        },

        stepBackSilent: function() {
            if(this.routeHistory.length < 2) {
                window.history.replaceState({},'Home','#home');
            } else {
                this.silentBack = true;
                window.history.back();
            }
        },

        updateTitle: function(hash) {
            var hashTitle = $('div[data-route="'+hash.substr(1).split('/')[0]+'"]').attr('data-title');
            document.title = (hashTitle ? (this.originalTitle + ' - ' + hashTitle) : (this.originalTitle || ""));
        },

        trackAnalytics: function(hash) {
            if(hash && window.ga) {
                ga('send', 'pageview', {
                    page: location.pathname+location.search+hash,
                    title: document.title
                });
            }
        },

        applyModuleConfig: function(config) {
            // Apply config to other modules as needed
            if(config) {
                var activeComponents = window.parent.getActiveComponents();
                $.extend(activeComponents,window.getActiveComponents());
                $.each(config,$.proxy(function(index,val){
                    var module = activeComponents[index];
                    if(module) {
                        module.refreshConfig(val);
                    } else {
                        // TODO - Throw remote error
                    }
                },this));
            }
        },

        // Router Aliases
        applyRouterAliases: function() {
            // Apply any aliases defined in config
            this.aliases = config.routeRedirect || [];
            $.each(this.aliases,function(i,r){
                if(r['tab']) {
                    $('body').livequery('a[href="' + r['from'].join('"],a[href="') + '"]',function (el) {
                        $(el).attr('href', r['to']).attr('target', '_blank');
                    });
                }
            });

            // fall back for forms links - all of them should point to the RP main url
            this.aliases.push({
                from: ['#register','#login','#forgot','#reset','#rp'],
                to: config.rpBaseUrl
            });
        },

        checkAlias: function(hash) {
            var matchingAlias = null;
            $.each(this.aliases,function(i,r){
                var from = (typeof r['from'] == 'string' ? r['from'].split() : r['from']); // Handle either string or array of strings in from field
                var matches = $.grep(from,function(a){
                    return ('#'+a.replace('#','')) == ('#'+hash.replace('#','').split('/')[0]);
                });
                if(matches.length > 0) {
                    matchingAlias = r;
                    return false;
                }
            });
            if(matchingAlias) {
                this.stepBackSilent();
                if(matchingAlias['tab']) {
                    window.open(matchingAlias['to'],'_blank'); // This is a fallback
                } else {
                    setTimeout(function(){
                        window.location = matchingAlias['to'] + (hash.indexOf('/') > -1 ? hash.substr(hash.indexOf('/')) : '');
                    },1000);
                }
                return true;
            }
            return false;
        },

        handleRampitInit: function() {
            if (window.bRampit && window.rampitInit) {
                rampitInit(window.location.hash.substr(1));
            }
        }
    }
});
