define('modules/process/hybrid/js/base.js',['app/module', 'app/config', 'jquery', 'app/util', 'templates', 'moment', 'swiper.jquery.min', 'jquery.livequery'], function (module, config, $, util, templates, moment) {
    return new module({
        name: 'process.hybrid',
        selectors: '.processHorizontalStepWrap',
        remoteData: [(config.urls.process)],

        config: {},

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        }],

        initialize: function (data) {
            this.data = data;
            this.renderTemplates(this.data);
        },

        onRender: function () {
            util.setVisibleInterval($(this.getSelectors()), $.proxy(this.initSwiper, this), 1000);  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

        methods: {

            renderTemplates: function (data) {
                $(this.getSelectors()).append(templates.processsteps({
                    steps: data.sort(function (a, b) {
                        return a.id - b.id;
                    })
                }));
            },

            initSwiper: function (data) {

                function incrementNum() {

                    var swiperActive = $('.swiper-slide-active');
                    var activeIndex = swiperActive.attr('data-processid');

                    swiperNum.text(activeIndex);
                    swiperBg.width((100 / slidesLength * (activeIndex)) + '%');
                }

                function disableHiddenTabs() {
                    // disable tab accessibility on not visible items
                }


                if (this.swiperInstance) {
                    return;
                }

                var swiper = $('.process .swiperMain'),
                    swiperNum = $('.processSwiperNavigateNum', swiper),
                    swiperBg = $('.processSwiperNavigateBg', swiper),
                    slidesLength = 0, instance;

                this.swiperInstance = new Swiper($('.swiper-container', swiper)[0], {
                    slidesPerView: 3,
                    initialSlide: 0,
                    slidesOffsetBefore: 300,
                    speed: 500,
                    spaceBetween: 50,
                    loop: true,
                    //next 4 lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: true,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: false,
                    slideToClickedSlide: true,
                    prevButton: $('.processSwiperNavigatePrev', swiper)[0],
                    nextButton: $('.processSwiperNavigateNext', swiper)[0],
                    // pagination
                    pagination: false,
                    preventClicks: false,
                    preventClicksPropagation: false,
                    breakpoints: {
                        1199: {
                            slidesPerView: 2.5

                        },
                        768: {
                            slidesPerView: 1.25,
                            spaceBetween: 20,
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false,
                            slidesOffsetBefore: 30
                        }
                    }
                });

                instance = this.swiperInstance;
                slidesLength = this.data.length;
                incrementNum();
                disableHiddenTabs();
                instance.on('slideChangeStart', $.proxy(incrementNum, this));

                instance.on('onSlidePrevStart', function () {
                    $('.swiper-slide-active').addClass('swiper-hide').delay(50).queue(function (next) {
                        $(this).addClass('swiper-show');
                        next();
                    });
                });

                instance.on('onSlidePrevEnd', function () {
                    $('.swiper-slide-active').removeClass('swiper-hide swiper-show');
                });

                instance.on('slideChangeEnd', $.proxy(disableHiddenTabs, this));

                $('.processContainer').on('click', '.processCardButton', function(e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    setTimeout(function() {
                        window.location.href = href;
                    }, 600);
                });
            }
        }
    })
})
;
