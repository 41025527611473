define('modules/news/promogrid/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.mobile.custom"], function (module, config, $, util, templates) {
    return new module({
        name: 'news',
        selectors: '.newsPage',
        remoteData: [config.urls.news],

        routes: [{
            route: 'news/(\\d+)',
            fn: function (hash) {
                var newsId = hash.split('/')[1];
                this.renderNewsDetails(newsId);
            },
            extra: true,
            delay: 10
        }, {
            route: 'news',
            fn: 'closeDetails',
            extra: true
        }],

        config: {
            promoCount: 3,
            useCarousel: true,
            showPromoItemsInListing: false
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());

            // sorting by date - latest are first;
            this.data = data.sort(function(a, b) {
                return new Date(b.date) - new Date(a.date);
            });

            // this adds long month names for the dates
            this.data = this.data.map(function(newsItem) {
                var date = new Date(newsItem.date);
                var newDate = date.toLocaleString('en-us', {month: 'long', day: 'numeric', year: 'numeric'});
                newsItem.date = newDate;
                return newsItem;
            });

            var shouldUseCarousel = this.getConfig().useCarousel || $(window).width() < 768;
            var promoNewsItems = data.slice(0, this.getConfig().promoCount);

            var regularNewsItems = (this.getConfig().showPromoItemsInListing ? data : data.slice(this.getConfig().promoCount));

            var categories = util.unique($.map(regularNewsItems, function (record) {
                return record.category;
            }));
            // 100&change2019 specific comment
            // categories.unshift('All Categories');

            this.$container.find('.newsContainer').html(templates.newspage({
                regularNewsItems: regularNewsItems,
                promoNewsItems: promoNewsItems,
                records: regularNewsItems,
                categories: $.map(categories, function (name) {
                    return {name: name};
                }),
                usecarousel: shouldUseCarousel
            }));

            if (data.length <= this.getConfig().promoCount) {
                // this.$container.find('.newsListing').remove();
            } else if (categories.length > 2) {
                // this.$container.find('.newsListingTabs .newsListingTab').on('click', $.proxy(function (e) {
                //     e.preventDefault();
                //     $(e.currentTarget).siblings().removeClass('active');
                //     $(e.currentTarget).addClass('active');
                //     this.switchPostListing($(e.currentTarget).text());
                // }, this));
                // this.$container.find('.newsListingTabs .newsListingTab').first().trigger('click');
            } else {
                // this.$container.find('.newsListingTabs').remove();
            }

            if (shouldUseCarousel) {
                // init carousel
                this.$container.find('.carousel.slide')
                    .carousel({
                        interval: 7500,
                        pause: null
                    }).swipeleft(function () {
                    $(this).carousel('next');
                });
            }

            this.attachEventHandlers();
        },

        onRender: function () {
            if (this['defferedRenderNewsDetail']) {
                this.renderNewsDetails(this['defferedRenderNewsDetail']);
            }
        },

        methods: {

            switchPostListing: function (category) {
                if (category == 'All Categories') {
                    this.$container.find('.newsListingEntry').show();
                } else {
                    this.$container.find('.newsListingEntry').hide();
                    this.$container.find('.newsListingEntry[data-category="' + category + '"]').show();
                }
            },

            renderNewsDetails: function (newsId) {
                if (!this.isReady()) {
                    this.defferedRenderNewsDetail = newsId;
                    return;
                }

                if ($('body').hasClass('menuOpened')) {
                    $('body').removeClass('menuOpened');
                };

                var dataLength = this.data.length;

                if (!this.newsById) {
                    var newsById = {};
                    $.each(this.data, $.proxy(function (index, item) {
                        item.originalIndex = index;
                        newsById[item.id] = item;
                    }, this));
                    this.newsById = newsById;
                }

                var newsItem = this.newsById[newsId];

                var renderedIndex = parseInt(newsItem.originalIndex, 10) + 1;

                // TODO: think about the possibility to have 1 or 2 news items of the same category
                // the category check is commented out because the project doesn't need it
                if (newsItem) {
                    var i = 0, currentItem;
                    if (!newsItem.prevItem) {
                        for (i = newsItem.originalIndex - 1; i >= 0; i--) {
                            currentItem = this.data[i];
                            // if (newsItem.category == currentItem.category) {
                                newsItem.prevItem = currentItem;
                                break;
                            // }
                        }
                        if (!newsItem.prevItem) {
                            for (i = this.data.length - 1; i > newsItem.originalIndex; i--) {
                                currentItem = this.data[i];
                                // if (newsItem.category == currentItem.category) {
                                    newsItem.prevItem = currentItem;
                                    break;
                                // }
                            }
                        }
                    }
                    if (!newsItem.nextItem) {
                        for (i = newsItem.originalIndex + 1; i < this.data.length; i++) {
                            currentItem = this.data[i];
                            // if (newsItem.category == currentItem.category) {
                                newsItem.nextItem = currentItem;
                                break;
                            // }
                        }
                        if (!newsItem.nextItem) {
                            for (i = 0; i < newsItem.originalIndex; i++) {
                                currentItem = this.data[i];
                                // if (newsItem.category == currentItem.category) {
                                    newsItem.nextItem = currentItem;
                                    break;
                                // }
                            }
                        }
                    }

                    this.$container.closest('body').addClass('locked');
                    // this.$container.closest('body').find('.navbarWrap').addClass('hidden').hide();

                    $('.newsDetailContainer').html(templates.newsDetailsModal({
                        item: newsItem,
                        itemIndex: renderedIndex,
                        itemLength: dataLength
                    }));

                    this.$container.find('.carousel.slide').carousel('pause');

                    setTimeout($.proxy(function () {
                        $('.newsDetailContainer').addClass('newsDetailsOpened');
                    }, this), 10);
                }

            },

            closeDetails: function () {
                if (this.isReady()) {
                    var newsDetailContainer = $('.newsDetailContainer');
                    $('body').removeClass('locked');
                    newsDetailContainer.removeClass('newsDetailsOpened');
                    // setTimeout($.proxy(function () {
                        // this.$container.find('.newsDetailContainer').empty();
                    // }, this), 1000);
                    // $('.navbarWrap').removeClass('hidden').show();
                    this.$container.find('.carousel.slide').carousel('cycle');
                }
            },

            attachEventHandlers: function () {
                var module = this;

                $('.newsDetailContainer').on("click", " .modalClose", $.proxy(function (e) {
                    e.preventDefault();
                    window.history.pushState('news', 'News', '#news');
                    this.closeDetails();
                }, this));

                this.$container.on('click', '.newsListingSpace', function(e) {
                    e.preventDefault();
                    var newsItemId = $(this).attr('href').split('/')[1];
                    var renderedNewsItemId = '#news/' + newsItemId;
                    module.renderNewsDetails(newsItemId);
                    window.history.pushState('news', 'News', renderedNewsItemId);
                });

                $('.newsDetailContainer').on('click', '.newsModalBtnNav', function(e) {
                    e.preventDefault();
                    var newsItemId = $(this).attr('href').split('/')[1];
                    var renderedNewsItemId = '#news/' + newsItemId;
                    module.renderNewsDetails(newsItemId);
                    window.history.pushState('news', 'News', renderedNewsItemId);
                });

                this.$container.on('click', '.promoCarouselMore a', function(e) {
                    e.preventDefault();
                    var newsItemId = $(this).attr('href').split('/')[1];
                    var renderedNewsItemId = '#news/' + newsItemId;
                    module.renderNewsDetails(newsItemId);
                    window.history.pushState('news', 'News', renderedNewsItemId);
                });

                this.$container.on('click', '.shareSocial', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var element = $(this);
                    module.onSocialClick(element);
                });
            },

            // This method is scoped to the element itself, not the module class
            onSocialClick: function (element) {

                var loc = location.href,
                    action = element.attr('data-action');

                if (action == 'twitter') {
                    var title = element.attr('data-title') || document.title;
                    window.open('//twitter.com/intent/tweet?url=' + encodeURIComponent(loc) + '&text=' + encodeURIComponent(title), 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'facebook') {
                    var t = element.attr('data-title') || document.title;
                    window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'youtube') {
                    var title = element.attr('data-title') || document.title;
                    window.location.assign("https://www.youtube.com/watch?v=" + title);
                } else if (action == 'google') {
                    window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'linkedin') {
                    var title = element.attr('data-title') || document.title;
                    var lishareText = element.attr('data-title') || document.title;
                    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(loc) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(lishareText), 'Linkedin Share', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                }
            }
        }

    });
});
