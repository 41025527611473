define('modules/footer/compact/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates"], function (module, config, $, util, templates) {
    return new module({
        name: 'footer',
        selectors: '.footer',

        initialize: function () {
            $(this.getSelectors()).append(templates.footer({
                config: config
            }));
        }
    })
});
