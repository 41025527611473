define('modules/navigation/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "app/redirect"], function (module, config, $, util, redirect) {
    return new module ({
        name: 'navigationbasic',
        selectors: false,
        remoteData: [],
        extendable: true,

        lastRoute: null, // Null defaults to home

        routes: [{
            route: '.*',
            fn: function (hash) {
                return this.navigate(hash);
            },
            priority: 10
        }, {
            route: '.*',
            fn: 'trackRoutes',
            extra: true
        },{
          route: '.*',
          fn: 'resetHomeVideo',
          extra: true
        },{
            route: 'readiness-tool',
            fn: function (hash) {
                var w = $('.typeform-widget'),
                    c = setInterval(function () {
                        if (w.find('iframe').length) {
                            clearInterval(c);
                            w.removeClass('typeform-widget-loading');
                        }
                    }, 300);
            },
            extra: true
        },
            {
                route: 'evaluation/(.*)',
                fn: function (hash) {

                    var scrollToSection = function (anchor) {
                        $('html, body').animate({
                            scrollTop: anchor.offset().top
                        }, 1500);
                    };

                    var evaluationScrollTo = hash.split('/')[1];

                    var elementOnPage = $('.evaluationPage .genericContentContainer .' + evaluationScrollTo);

                    if (elementOnPage.length > 0) {
                        scrollToSection(elementOnPage);
                    } else {
                        var insertListener = function (event) {
                            if (event.animationName == "elementInserted") {
                                // This is the debug for knowing our listener worked!
                                // event.target is the new node!
                                // console.warn("Another node has been inserted! ", event, event.target);

                                var scrollTarget = $(event.target);

                                if (scrollTarget.hasClass(evaluationScrollTo)) {
                                    $(document).ajaxStop(function () {
                                        scrollToSection(scrollTarget);
                                    });
                                }
                            }
                        }

                        document.addEventListener("animationstart", insertListener, false); // standard + firefox
                        document.addEventListener("MSAnimationStart", insertListener, false); // IE
                        document.addEventListener("webkitAnimationStart", insertListener, false); // Chrome + Safari
                    }
                },
                extra: true
            }],

        config: {
            smoothNavigate: true
        },


        initialize: function () {

        },

        methods: {

            navigate: function (hash) {
                //>>excludeStart("production",pragmas.production);
                console.debug('Basic navigation module trigger: ', hash);
                //>>excludeEnd("production");
                hash = this.setDefaultRoute(hash);
                if (hash) {

                  var pages = this.getNewPage(hash);

                  if (pages) {

                    if (pages.currentPage.is(pages.newPage)) {
                      // if currentPage and newPage are the same we don't do anything
                    } else {
                      // if currentPage and newPage are different, we are calling switchPages
                      this.switchPages(pages);
                    };
                  } else {
                    // This will open 404 page - handled in router.js
                    return false
                  };
                };
            },

            setDefaultRoute: function (hash) {
                // Set the default starting route
                if ((!hash && this.lastRoute === null) || (hash.length < 1)) {
                    return 'home';
                } else if (this.lastRoute == hash) {
                    return false;
                }
                return hash;
            },

            getNewPage: function (hash) {
                // Get our pages
                var $currentPage = $('.pageContainer[data-route="' + this.lastRoute + '"]'),
                    $newPage = $('.pageContainer[data-route="' + hash.split('/')[0] + '"]'),
                    pages = {};

                if(!$newPage || $newPage.length === 0) {
                  // this signals for 404 page - handled in navigate method and in router.js
                    return false;
                } else {
                  // If currentPage and newPage are different we want to trigger switchPages with the new pages variables
                    pages.currentPage = $currentPage;
                    pages.newPage = $newPage;
                    return pages;
                };
            },

            switchPages: function (pages) {
                if (pages.currentPage && pages.currentPage.length > 0 && pages.currentPage.attr('data-route-trackscroll') != undefined) {
                    pages.currentPage.attr('data-route-trackscroll', util.getScrollParent(pages.currentPage).scrollTop());
                };

                $('.pageContainer').removeClass('active').hide();
                pages.newPage.addClass('active').show().css('opacity', 1);

                // this is specific to 100&Change 2019
                $('.js-scroll-btn-handler').removeClass('not-visible');

                // closing menu
                $('body').removeClass('menuOpened');

                if (typeof pages.newPage.attr('data-route-trackscroll') != 'undefined') {
                    util.getScrollParent(pages.newPage).scrollTop(pages.newPage.attr('data-route-trackscroll'));
                } else {
                    if (this.smoothNavigate) {
                        util.scrollBodyToTop();
                    }
                    else {
                        // util.scrollBodyToTop(0);
                        var hash = window.location.hash.split('/')[0];
                        var subpage = window.location.hash.split('/')[1];
                        if (hash === '#results' && !subpage) {
                            util.scrollBodyToTop(0);
                        } else if (hash === '#results' && subpage === 'awardee') {
                            setTimeout(function() {
                                $('html, body').animate({ scrollTop: ($('.grantee-wrapper').offset().top)}, 1000);
                            }, 100);
                        } else if (hash === '#results' && subpage === 'finalists') {
                            setTimeout(function() {
                                $('html, body').animate({ scrollTop: ($('.finalists-headings').offset().top)}, 1000);
                            }, 100);
                         } else {
                            util.scrollBodyToTop(0);
                        }

                        if (hash !== '#home' && hash !== '' && hash !== '#results') {

                            if(!$.cookie('usr-announcement')) {
                                $('.singlePageWrap').addClass('announcement-shown');
                                $('.awardee-announcement').addClass('shown');
                            } else {
                                $('.singlePageWrap').removeClass('announcement-shown');
                                $('.awardee-announcement').removeClass('shown');
                            }

                        } else {
                            $('.singlePageWrap').removeClass('announcement-shown');
                            $('.awardee-announcement').removeClass('shown');
                        }
                    }
                }

            },

            trackRoutes: function (hash) {
                this.lastRoute = hash;
            },

            resetHomeVideo: function() {
              var homeVideo = $('.videoContainerWrapper').attr('data-videoid');
              $('.videoContainerWrapper').empty();
              $('.videoContainerWrapper').html('<div class="videoContainer" data-youtube="'+homeVideo+'" data-autoplay="0" data-poster="img/100change-video-poster.jpg" data-title="100&amp;Change" data-subtitle="Competition 2019"></div>');
            }

        }
    });
});

