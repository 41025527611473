define('hooks',['jquery', 'app/util', 'templates', 'swiper.jquery.min', 'jquery.livequery', 'focusvisible',], function ($, util, templates) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.')
            //>>excludeEnd("production");

        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.')
            //>>excludeEnd("production");

            // array.find ie support
            if (typeof Array.prototype.find === 'undefined') {

                Array.prototype.find = function (callback, thisArg) {
                    for (var i = 0; i < this.length; i++) {
                        if (callback.call(thisArg || window, this[i], i, this))
                            return this[i];
                    }
                    return undefined;
                };
            }

            $(document).on('click', '.missing-page-hook', function () {
                $('.missingParallaxScene__layer--two').addClass('slideUp');
            });

            var $body = $('body');
            var $modalFinalists = $('.modal-finalists');

            var hash = window.location.hash.split('/')[0];
            setTimeout(function () {
                if((!$.cookie('usr-finalists') && hash === '#home') || (!$.cookie('usr-finalists') && hash === '')) {
                    $modalFinalists.show();
                    $modalFinalists.addClass('shown');
                    $body.addClass('locked');
                }
            }, 400);

            $('.btn-close', $modalFinalists).on('click', function (e) {
                var date = new Date();
                date.setTime(date.getTime() + (1440 * 360 * 60 * 1000));
                $.cookie('usr-finalists', true);
            });

            $('.announcement-close-button').on('click', function (e) {
                var date = new Date();
                date.setTime(date.getTime() + (1440 * 360 * 60 * 1000));
                $.cookie('usr-announcement', true);
                $('.awardee-announcement').removeClass('shown');
                $('.singlePageWrap').removeClass('announcement-shown');
            });

            $modalFinalists.find('.btn-close').on('click', function () {
                $modalFinalists.removeClass('shown');
                $body.removeClass('locked');
            });

            $('.btn-primary', $modalFinalists).on('click', function (event) {
                $modalFinalists.removeClass('shown');
                $body.removeClass('locked');
            });

            setTimeout(function () {
                $('.sidebar .sidebarInnerWrap .sidebarItemNested[href^="https://www.macfound.org"]').attr('target', '_blank');
            }, 3000);

            $body.on('click', function(e) {

                if ($(window).width() > 786) {
                    var $self = $(this);
                    var $target = $(e.target);

                    if ($self.hasClass('menuOpened')) {

                        if ($target.closest('.sidebar').length || $target.hasClass('.sidebar')) {
                            // click on the sidebar
                        } else {
                            // click outside of the sidebar when the menu is opened
                            // the first click is for the navigation button which opens the sidebar that's why we need the next check
                            if ($target.closest('.jsMenuControl').length || $target.hasClass('jsMenuControl')) {
                                // this is the click which opens the sidebar
                            } else {
                                // This is the desired event to close the sidebar on body click (if it's open)
                                $self.removeClass('menuOpened');
                            }
                        }
                    }
                }
            });

            // inaction swiper
            var inActionWrapper = $('.homeInActionCardWrapper');
            var inActionHtml = inActionWrapper.html();

            $(window).resize(function () {

                if ($(window).width() <= 992) {
                    setTimeout(function () {
                        inActionWrapper.removeClass('static');
                        var mySwiper = new Swiper('.homeInActionCardWrapper .swiper-container', {
                            slidesPerView: 4,
                            initialSlide: 0,
                            loop: false,
                            simulatesTouch: false,
                            shortSwipes: false,
                            longSwipes: false,
                            onlyExternal: false,
                            slideToClickedSlide: false,
                            pagination: false,
                            preventClicks: false,
                            preventClicksPropagation: false,
                            prevButton: $('.homeInActionSwiperNavigatePrev')[0],
                            nextButton: $('.homeInActionSwiperNavigateNext')[0],
                            breakpoints: {
                                992: {
                                    slidesPerView: 2,
                                    initialSlide: 0,
                                    spaceBetween: 20,
                                    speed: 500,
                                    loop: true,
                                    simulatesTouch: true,
                                    shortSwipes: true,
                                    longSwipes: true,
                                    onlyExternal: false,
                                    slidesOffsetBefore: 30,
                                    slideToClickedSlide: true,
                                    pagination: false,
                                    preventClicks: false,
                                    preventClicksPropagation: false
                                },
                                768: {
                                    slidesPerView: 1.25,
                                    initialSlide: 0,
                                    spaceBetween: 20,
                                    speed: 500,
                                    loop: true,
                                    simulatesTouch: true,
                                    shortSwipes: true,
                                    longSwipes: true,
                                    onlyExternal: false,
                                    slidesOffsetBefore: 30,
                                    slideToClickedSlide: true,
                                    pagination: false,
                                    preventClicks: false,
                                    preventClicksPropagation: false
                                }
                            }
                        });

                    }, 1000);
                }
                else if (!inActionWrapper.hasClass('static')) {
                    inActionWrapper.empty().html(inActionHtml).addClass('static');
                }
            });


            // template
            $('.scrollToTopBtn').html(templates.scrolltotop());
            $('.js-scroll-btn-handler').on('click', 'button', function() {
                console.log('scroll btn click registered');
                $('html, body').animate({scrollTop: 0}, 1000);
            });
        }
    };
    $(document).ready(methods.onDocumentReady);

    return methods;
});

