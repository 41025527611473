// require all needed dependencies here
define('modules/accessibility/js/base.js',["app/config", "jquery", "app/module", "app/util", "templates", "app/event-bus", "focusvisible", "jquery.livequery"], function (config, $, module, util, templates, eventBus) {
    return new module({
        name: 'accessibility',

        routes: [{
            route: '.*',
            fn: 'accessibleCloseModals',
            extra: true,
            delay: 100
        }, {
            route: '.*',
            fn: function (hash) {
                this.accessibleHTags(hash);
            },
            extra: true,
            delay: 250
        }, {
            route: 'timeline',
            fn: 'accessibleTimeline',
            extra: true,
            delay: 250
        }, {
            route: 'scoring',
            fn: 'accessibleScoring',
            extra: true,
            delay: 250
        }, {
            route: 'wise-head-panel',
            fn: 'accessibleJudges',
            extra: true,
            delay: 250
        }, {
            route: 'news',
            fn: 'accessibleNews',
            extra: true,
            delay: 250
        }],

        config: {},

        // once the remote data is fetched this method will be called
        initialize: function (data) {
            // the selector will be always present in the DOM, please use the cached version whenever possible
            this.$container = $(this.getSelectors());
            this.data = data;
            this.moduleDataAlreadyLoaded = false;
            this.renderTemplates(data);
            this.attachEventHandlers();
            this.setInitialState();

        },

        // once the executon of initialize has ended this method will be called
        onRender: function () {

        },

        methods: {
            renderTemplates: function (data) {
                // modify the data if needed and prepare a view model forrendering the view
                var viewModel = data;

                // render the template and append it to the container
                // most of the time we are refering to a Handlebars template existing in the module itself
                // the template can be overriden if a file with the same name exists in the project specific folder
                //this.$container.append(templates.unique_Template_Name(viewModel));
            },

            attachEventHandlers: function (data) {

                var module = this;

                // Sidebar Menu

                // open sidebar menu
                $(document).on('click', '.jsMenuControl', function () {

                    module.accessibleDisableHTags();

                    $('.navbarWrap a, .pageContainer.active a, .pageContainer.active button').attr('tabindex', -1);

                    $('.sidebar a:not(.sidebarItemNested), .sidebar .sidebarItemNestedParent, .sidebar button').attr('tabindex', 1);

                    if ($('.sidebarItemNestedParent').hasClass('sidebarItemSelected')) {

                        $('.sidebarItemNestedParent.sidebarItemSelected').find('.sidebarItem.sidebarItemNested').attr('tabindex', 1);
                    }

                    $('.sidebar .sidebarItemNestedParent').on('keypress', function (e) {
                        if (e.which == 13) {

                            $(this).trigger('click');
                            $(this).find('.sidebarItemNested').attr('tabindex', function (index, attr) {
                                return attr == '1' ? '-1' : '1';
                            });

                        }
                    });
                });

                // close sidebar menu
                $(document).on('click', '.sidebar .closeButton', function () {

                    module.accessibleResetHTags();

                    $('.navbarWrap a, .pageContainer.active a:not(".skipToContent, .newsModal a, .newsModal button"), .pageContainer.active button').removeAttr('tabindex');
                    $('.sidebar a, .sidebar .sidebarItemNestedParent, .sidebar button').attr('tabindex', -1);
                });


                // Faq Cards

                // faq card open
                $(document).on('click', '.qaCardInner', function () {

                    module.accessibleDisableHTags();

                    $('.navbarWrap a, .pageContainer.active a, .pageContainer.active button').attr('tabindex', -1);
                    $('.faqDetailOverlay .faqNavClose, .faqDetailOverlay .faqDetailCard').attr('tabindex', 1);

                    $('.faqDetailCard.active').focus();
                    $('.faqDetailCard.active').find('a').attr('tabindex', 1);


                    $('.faqDetailOverlay .faqDetailCard').on('click', function (e) {
                        $('.faqDetailCard a').attr('tabindex', -1);
                        $(this).find('a').attr('tabindex', 1);
                    });

                });

                // faq card close
                $(document).on('click', '.faqNavClose', function () {

                    module.accessibleResetHTags();

                    $('.navbarWrap a, .pageContainer.active a:not(".skipToContent, .newsModal a, .newsModal button"), .pageContainer.active button').removeAttr('tabindex');
                    $('.faqDetailOverlay .faqNavClose, .faqDetailOverlay .faqDetailCard, .faqDetailOverlay .faqDetailCard a').attr('tabindex', -1);
                });

                // Learn More

                $(document).on('click', '.skipToContent', function (e) {
                    e.preventDefault();
                });

                $(document).on('keypress', '.js-learn-more-btn', function (e) {
                    if (e.which == 13) {

                        $('.pageContainer.active .skipToContent').attr('tabindex', 0).focus().attr('tabindex', -1);

                    }
                });

            },

            accessibleCloseModals: function () {

                // Close all modals on route update
                $(document).find('.sidebar .closeButton').trigger('click');
                $(document).find('.faqNavClose').trigger('click');
            },

            accessibleHTags: function (hash) {

                $(eventBus).bind('route-data-loaded', $.proxy(function (e, payload) {
                    $('.pageContainer[data-route="' + payload.route + '"] .introSection, .pageContainer[data-route="' + payload.route + '"] .genericContentContainer, .pageContainer[data-route="' + payload.route + '"] .lastCardActionArea').find('h1,h2,h3,h4,h5,h6').attr('tabindex', 0)

                    if (payload.route == "home") {
                        $('.homePageWrap .processIntro, .homePageWrap .faqIntro, .homePageWrap .inActionIntro').find('h1,h2,h3,h4,h5,h6').attr('tabindex', 0)
                    }
                }, this))
            },

            accessibleTimeline: function () {

                // timeline specific accessibility

                // disable tabbing on all details and past point navigation
                $('.timelineBox, .timelineBoxDetails a, .timelineItemLink.pastPoint').attr('tabindex', -1);

                // focus on the active item only when navigation with the keyboard
                $('.timelineItemLink').on('keypress', function (e) {

                    if (e.which == 13) {
                        $('.timelineBox, .timelineBoxDetails a, .timelineItemLink.pastPoint').attr('tabindex', -1);

                        setTimeout(function () {
                            $('.timelineBox.selectedBox').focus().attr('tabindex', 1);
                            $('.timelineBox.selectedBox').find('.timelineBoxDetails a').attr('tabindex', 1);
                        }, 1200);

                    }
                });

                // exit selected box on escape key
                $('.timelineBox').on('keyup', function (e) {
                    if ($(this).hasClass('focus-visible')) {

                        if (e.which == 27) {

                            $('.timelineBox, .timelineBoxDetails a, .timelineItemLink.pastPoint').attr('tabindex', -1);
                            $('.timelineItemLink.selected').focus();
                        }

                        if (e.which == 9) {
                            if (e.shiftKey) {
                                $('.timelineBox, .timelineBoxDetails a, .timelineItemLink.pastPoint').attr('tabindex', -1);
                                $('.timelineItemLink.selected').focus();
                            }
                            else {
                                //Focus next input
                            }
                        }
                    }

                });

                // return to the sidenav when we tab out of the last selected box link
                $('.timelineBoxBtn').on('keydown', function (e) {
                    if (e.which == 9) {
                        if (e.shiftKey) {
                            //
                        }
                        else {
                            $('.timelineBox, .timelineBoxDetails a, .timelineItemLink.pastPoint').attr('tabindex', -1);
                            $('.timelineItemLink.selected').focus();
                        }
                    }
                });

            },

            accessibleScoring: function () {

                // scoring specific accessibility
                $('.scoreSection .slider-handle').attr('tabindex', -1);

                $('.js-scoringNav-button').on('keypress', function (e) {

                    if (e.which == 13) {

                        setTimeout(function () {
                            $('.scoreEntry.active .slider-handle').focus().attr('tabindex', 0);
                        }, 1000);

                    }
                });

                $('.slider-handle').on('keyup', function (e) {

                    if ($(this).hasClass('focus-visible')) {
                        if (e.which == 27) {
                            $(this).attr('tabindex', -1);
                            $('.js-scoringNav-button.active').focus();
                        }
                    }

                });

                $('.slider-handle').on('keydown', function (e) {

                    if ($(this).hasClass('focus-visible')) {
                        if (e.which == 9) {
                            $('.js-scoringNav-button.active').focus();
                            $(this).attr('tabindex', -1);
                        }
                    }

                });


            },

            accessibleJudges: function () {

                var module = this;

                // sort by judge keypress
                $(document).on('keypress', '.js-alphaGroup', function (e) {

                    if (e.which == 13) {

                        setTimeout(function () {
                            $('.judgeBox').first().focus();
                        }, 1000);


                    }
                });

                // open judges modal
                $(document).on('click', '.judgeBox', function () {

                    module.accessibleDisableHTags();

                    $('.navbarWrap a, .pageContainer.active a, .pageContainer.active button').attr('tabindex', -1);

                    $('.judgeDetailContainer.active a, .judgeDetailContainer.active button').attr('tabindex', 1);


                });

                // close judges modal
                $(document).on('click', '.judgeDetailClose', function () {

                    module.accessibleResetHTags();

                    $('.navbarWrap a, .pageContainer.active a:not(".skipToContent, .newsModal a, .newsModal button"), .pageContainer.active button').removeAttr('tabindex');
                    $('.judgeDetailContainer.active a, .judgeDetailContainer.active button').removeAttr('tabindex');

                });

            },

            accessibleNews: function () {

                var module = this;

                // open news modal
                $(document).on('click', '.promoCarouselMore a, .newsListingSpace, .newsModalBtnNav', function () {

                    module.accessibleDisableHTags();

                    $('.navbarWrap a, .pageContainer.active a:not(".skipToContent, .newsModal a, .newsModal button"), .pageContainer.active button').attr('tabindex', -1);

                    $(document).find('.newsDetailContainer a, .newsDetailContainer button').attr('tabindex', 1);


                });

                // close news modal
                $(document).on('click', '.newsModalCloseBtn', function () {

                    module.accessibleResetHTags();

                    $('.navbarWrap a, .pageContainer.active a:not(".skipToContent, .newsModal a, .newsModal button"), .pageContainer.active button').removeAttr('tabindex');
                    $('.newsDetailContainer a, .newsDetailContainer button').attr('tabindex', -1);

                });

            },

            accessibleDisableHTags: function () {

                $('.homePageWrap .processIntro, .homePageWrap .faqIntro, .homePageWrap .inActionIntro').find('h1,h2,h3,h4,h5,h6').attr('tabindex', -1);

                $('.introSection, .genericContentContainer, .lastCardActionArea').each(function () {
                    $(this).find('h1,h2,h3,h4,h5,h6').attr('tabindex', -1);
                });
            },

            accessibleResetHTags: function () {

                $('.homePageWrap .processIntro, .homePageWrap .faqIntro, .homePageWrap .inActionIntro').find('h1,h2,h3,h4,h5,h6').attr('tabindex', 0);

                $('.introSection, .genericContentContainer, .lastCardActionArea').each(function () {
                    $(this).find('h1,h2,h3,h4,h5,h6').attr('tabindex', 0);
                });
            },

            setInitialState: function (data) {

            }
        }

    });
});
