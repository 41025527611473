define('modules/judges/catalog/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery"], function (module, config, $, util, templates, livequery) {
    return new module({
        name: 'judges.basic',
        selectors: '.judgePageWrap, .judgeSection',
        remoteData: [config.urls.judges],
        routes: [{
            route: 'judges/(.*)',
            fn: function (hash) {
                $('#judges .judgebox[data-judge="' + hash.split('/')[1] + '"]').trigger('click');
            },
            extra: true,
            delay: 2500
        }, {
            route: 'wise-head-panel/(.*)',
            fn: function (hash) {

                var module = this;
                module.onJudgeCloseClick();
                var parsedJudgeName = hash.split('/')[1].toLowerCase();
                var parsedJudgeGroup = parsedJudgeName.charAt(0);

                var judgeSelector = '#judges .judgeBox[data-judge-slug="' + parsedJudgeName + '"]';

                $('.js-alphaGroup[data-alphagroup="' + parsedJudgeGroup + '"]').trigger('click');

                setTimeout(function () {
                    $(judgeSelector).trigger('click');
                }, 1200);

            },
            extra: true,
            delay: 1500
        }],


        config: {},

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.clickHandlers(this.data);
            this.scrollEvent();
        },

        methods: {
            renderTemplates: function (data) {
                var judgeGroups = [],
                    judgeSubGroups = [],
                    templateData = {
                        judgeGroups: [],
                        judgeSubGroups: []
                    };

                $.each(data, function (index, record) {

                    if (!judgeSubGroups[record.subgroup]) {
                        judgeSubGroups[record.subgroup] = {
                            name: record.subgroup,
                            parent: record.group,
                            parentIndex: record.group.replace(/\D/g, '')
                        };
                    }


                    if (!judgeGroups[record.group]) {
                        judgeGroups[record.group] = {
                            name: record.group,
                            title: record.shortDescription,
                            cms: record.group,
                            records: [],
                            subgroups: []
                        };
                    }

                    judgeGroups[record.group].records.push(record);
                    judgeGroups[record.group].subgroups.push(record.subgroup);
                });

                for (var key in judgeGroups) {
                    if (judgeGroups.hasOwnProperty(key)) {
                        templateData.judgeGroups.push(judgeGroups[key]);
                    }
                }

                for (var key in judgeSubGroups) {
                    if (judgeSubGroups.hasOwnProperty(key)) {
                        templateData.judgeSubGroups.push(judgeSubGroups[key]);
                    }
                }

                this.templateData = templateData;

                $('.judgePageWrap').append(templates.judgespage({
                    templateData: templateData,
                    config: this.config
                }));

                $('.alphaJudgeGroupContainer').html(templates.judgegroup({
                    data: templateData.judgeGroups[0],
                    nextGroup: templateData.judgeGroups[1]
                }));

                this.wrapJudgeSubGroup();
                //this.scrollEvent();

                $('.alphaJudgeGroupContainer').removeClass('fadeOut').addClass('fadeIn');


            },
            clickHandlers: function () {
                //$(this.getSelectors()).on('click', '.judgeBox', $.proxy(this.onJudgeBoxClick, this));

                var module = this;

                $(this.getSelectors()).on('click', '.judgeBox', function (e) {
                    module.onJudgeBoxClick(e);
                });

                $(this.getSelectors()).find('.judgeDetailClose').on('click', $.proxy(this.onJudgeCloseClick, this));
                $(this.getSelectors()).find('.js-alphaGroup').on('click', $.proxy(this.onAlphaGroupClick, this));

                //$(this.getSelectors()).find('.js-alphaGroupButton').on('click', $.proxy(this.onAlphaGroupClick, this));


                $(this.getSelectors()).on('click', '.js-alphaGroupButton', function () {
                    var nextGroupButton = $(this).attr('data-nextGroup');
                    $('.js-alphaGroup[data-alphagroup="' + nextGroupButton + '"]:first').trigger('click');
                });

                if ($(window).width() <= 768) {
                    $('.js-judgeCatalogContainer__sort-by').on('click', function () {
                        $(this).parents('.js-judgeCatalogContainer').toggleClass('expand');
                    });
                }


            },

            scrollEvent: function () {

                if ($(window).width() <= 768) {
                    // $('body').livequery('.wiseHeadPageContainer.active', $.proxy(function () {
                    //     $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(10, util.minScrollSpeed($.proxy(function (event) {
                    //
                    //         var wiseHeadLastCardInView = util.isElInView($('.wiseHeadLastCard'), false);
                    //
                    //         if (wiseHeadLastCardInView) {
                    //             if (!$('.js-judgeCatalogContainer').hasClass('fadeOut')) {
                    //                 $('.js-judgeCatalogContainer').addClass('fadeOut');
                    //             }
                    //         }
                    //         else {
                    //             if ($('.js-judgeCatalogContainer').hasClass('fadeOut')) {
                    //                 $('.js-judgeCatalogContainer').removeClass('fadeOut');
                    //             }
                    //         }
                    //
                    //     }, this))));
                    // }, this));

                    $(document).on('scroll', function () {
                        var judgesContainer = $('.judgesScrollTarget');
                        var navDesktop = $('.js-judgeCatalogContainer');
                        var topOfJudgesContainer = judgesContainer.offset().top
                        var bottomOfJudgesContainer = topOfJudgesContainer + judgesContainer.outerHeight(true)
                        var scrollTop = $(document).scrollTop();

                        if (scrollTop > topOfJudgesContainer) {
                            navDesktop.removeClass('judgeCatalogContainer--absolute');
                        }

                        if (scrollTop > bottomOfJudgesContainer - $(window).innerHeight() && !navDesktop.hasClass('judgeCatalogContainer--relative')) {
                            navDesktop.addClass('judgeCatalogContainer--absolute');
                        }

                    });
                }

            },

            onAlphaGroupClick: function (e) {
                var alphaId = $(e.delegateTarget).attr('data-alphaid'),
                    alphaSubGroup = $(e.delegateTarget).attr('data-alphasubgroup'),
                    alphaData = this.templateData,
                    nextGroupId = parseInt(alphaId) + 1,
                    module = this;

                if ($('.js-alphaGroup.active').attr('data-alphaid') == alphaId) {

                    $('html,body').animate({
                        scrollTop: $('.judgeSubGroup--' + alphaSubGroup + '').offset().top
                    }, 500);
                    $(window).trigger('resize');

                }
                else {
                    $('.alphaJudgeGroupContainer').removeClass('fadeIn').addClass('fadeOut');

                    setTimeout(function () {

                        $('.alphaJudgeGroupContainer').html(templates.judgegroup({
                            data: alphaData.judgeGroups[alphaId],
                            nextGroup: alphaData.judgeGroups[nextGroupId]
                        }));
                        
                        module.wrapJudgeSubGroup();

                        $('html,body').animate({
                            scrollTop: $('.judgeSubGroup--' + alphaSubGroup + '').offset().top
                        }, 500);
                        $(window).trigger('resize');

                        setTimeout(function () {
                          $('.alphaJudgeGroupContainer').removeClass('fadeOut').addClass('fadeIn');
                        }, 500);

                    }, 500);
                }

                $('.js-alphaGroup').removeClass('active');
                $(e.delegateTarget).addClass('active');

                if (nextGroupId == alphaData.judgeGroups.length) {
                    nextGroupId = 0;
                }


            },

            onJudgeCloseClick: function () {

                var moduleGetSelectors = $(this.getSelectors());

                $('.js-scroll-btn-handler').removeClass('not-visible');

                $(this.getSelectors()).find('#judgesDetail').removeClass('judgeDetailOpen').delay(1000).queue(function (next) {
                    $(this).hide();
                    moduleGetSelectors.find('#judgesDetail').hide();
                    moduleGetSelectors.find('.judgeDetailContainer').removeClass('active');
                    moduleGetSelectors.find('.judgeInfoGroup').removeClass('active');
                    moduleGetSelectors.find('.judgeInfoContainer').removeClass('active');
                    moduleGetSelectors.find('.judgesBasicNav .currentCount').html('');
                    $('body').removeClass('locked');
                    $('.navbarWrap').fadeIn();
                    next();
                });
            },

            onJudgeBoxClick: function (e) {
                var jGroup = $(e.currentTarget).attr('data-judgegroup'),
                    jId = $(e.currentTarget).attr('data-judge');

                $('body').addClass('locked');
                $('.navbarWrap').fadeOut();

                $('.js-scroll-btn-handler').addClass('not-visible');

                $(this.getSelectors()).find('#judgesDetail').show().delay(50).queue(function (next) {
                    $(this).addClass('judgeDetailOpen');
                    next();
                });

                $(this.getSelectors()).find('.judgeDetailContainer[data-judgegroup="' + jGroup + '"]').addClass('active');
                $(this.getSelectors()).find('.judgeInfoGroup[data-judgegroup="' + jGroup + '"]').addClass('active');
                $(this.getSelectors()).find('.judgeInfoContainer[data-judge="' + jId + '"]').addClass('active');

                var activeJudgeId = $(this.getSelectors()).find('.judgeInfoGroup.active .judgeInfoContainer.active').index() + 1;
                $(this.getSelectors()).find('.judgeDetailCounter .currentCount').html(activeJudgeId);

                if ($(this.getSelectors()).find('.judgeDetailCounter .currentCount').html().length == 1) {
                    $('.currentCountPre').show();
                }

                else {
                    $('.currentCountPre').hide();
                }

                this.judgesBasicGroupNav();
            },

            judgesBasicGroupNav: function () {
                var moduleGetSelectors = $(this.getSelectors()).find('.judgeDetailContainer.active'),
                    activeIndex,
                    lastIndex,
                    nextIndex,
                    prevIndex;

                // handle jude nav in it's own module
                $(this.getSelectors()).find('.judgeDetailNav.prev, .judgeDetailNav.next').unbind('click').on('click', $.proxy(function (e) {
                    activeIndex = +(moduleGetSelectors.find('.judgeDetailCounter .currentCount').html());
                    nextIndex = activeIndex + 1;
                    prevIndex = activeIndex - 1;
                    lastIndex = +(moduleGetSelectors.find('.judgeDetailCounter .totalCount').html());

                    //next at end
                    if ($(e.currentTarget).hasClass('next') && (activeIndex == lastIndex)) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active');
                        moduleGetSelectors.find('.judgeInfoContainer').eq(0).addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html('1');
                    }
                    // next
                    else if ($(e.currentTarget).hasClass('next')) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active').next('.judgeInfoContainer').addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(nextIndex);
                    }
                    // prev at end
                    else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active');
                        moduleGetSelectors.find('.judgeInfoContainer').eq(lastIndex - 1).addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(lastIndex);
                    }
                    else if ($(e.currentTarget).hasClass('prev')) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active').prev('.judgeInfoContainer').addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(prevIndex);

                        //.text(("0" + (parseInt(index) + 1)).slice(-2));
                    }

                    if (moduleGetSelectors.find('.judgeDetailCounter .currentCount').html().length == 1) {
                        $('.currentCountPre').show();
                    }

                    else {
                        $('.currentCountPre').hide();
                    }

                    $('#judgesDetail').scrollTop(0);
                    $('.judgeDetailContainer.active .judgeInfoGroupContainer').scrollTop(0);
                    $('.judgeDetailContainer.active').scrollTop(0);
                }));
            },

            wrapJudgeSubGroup: function () {
                var activeSubGroups = []; // An array to hold the class names

                // iterate all elements of common-class
                $('.judgeBox').each(function () {
                    // get the last class name
                    var judgeSubGroupAttr = $(this).attr('data-judgesubgroup');

                    // store the class name in the array if not already done
                    if (activeSubGroups.indexOf(judgeSubGroupAttr) == -1) {
                        activeSubGroups.push(judgeSubGroupAttr);
                    }
                });

                // iterate the array
                activeSubGroups.forEach(function (attr) {
                    // for each class name, wrap elements in new-parent
                    $('.judgeBox[data-judgesubgroup="' + attr + '"]').wrapAll('<section class="judgeSubGroup judgeSubGroup--' + attr + '"/>');
                    $('.judgeSubGroup--' + attr + '').prepend('<div class="judgeGroupHeading"><h4 class="judgeHeadingTitle">' + attr + '</h4></div>');

                });
            }
        }
    });
});

