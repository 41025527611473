define('modules/faqs/overlay/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery", "app/features/expandergrid"], function (module, config, $, util, templates) {
    return new module({
        name: 'faqs.overlay',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],

        config: {
            wrapRow: 3
        },

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.clickHandlers(this.data);
            this.wrapRows();

        },

        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length;
                });
                $(this.getSelectors()).append(templates.faqcards({
                    records: data
                }));

                var module = this;

                // $(window).resize(function () {
                //     $(module.getSelectors()).find('.faqNavClose').trigger('click');
                //     module.wrapRows();
                // });

                $('.faqDetailCard, .faqDetailCardAnswer a').attr('tabindex', '-1');

            },

            wrapRows: function () {
                var w = $(window).width();
                var divs = $(".qaCard");

                divs.unwrap();

                if (w > 768) {
                    for (var i = 0; i < divs.length; i += this.getConfig().wrapRow) {
                        divs.slice(i, i + this.getConfig().wrapRow).wrapAll('<div class="row"></div>');
                    }
                }

                else {
                    for (var i = 0; i < divs.length; i += 1) {
                        divs.slice(i, i + 1).wrapAll('<div class="row"></div>');
                    }
                }
            },

            getActiveColumn: function () {
                return $('.expanderGrid').find('.qaCardInner[data-active="true"]').first();
            },

            clickHandlers: function (data) {
                var gridCount = 0;
                $('.expanderGrid').find('.qaCardInner').each(function (e) {
                    gridCount++;
                });

                //$(this.getSelectors()).find('.counter .total').text(gridCount);

                // minus one since zero index
                var gridCountIndex = gridCount - 1;
                var activeIndex;
                var nextIndex;
                var prevIndex;
                var module = this;

                $(this.getSelectors()).find('.qaCardInner').on('click', function (e) {
                    e.preventDefault();

                    var index = $(this).attr('index');

                    $('.faqDetailOverlay').find('.counter .index').text(("0" + (parseInt(index) + 1)).slice(-2));

                    // Show overlay with active items
                    $('body').addClass('locked');
                    $('.navbarWrap').fadeOut();

                    $('.faqDetailOverlay').addClass('showDetail');

                    $('.faqDetailOverlay').find('.faqDetailCard').removeClass('active');

                    $('.faqDetailOverlay').find('.faqDetailCard[index="'+index+'"]').addClass('active');

                    setTimeout(function(){
                      if ($(window).width() > 768) {
                        $('.expanderContent').animate({
                          scrollTop: ($('.expanderContent').scrollTop() + $('.faqDetailCard.active').position().top) - 50
                        }, 500);
                      }
                      else {
                        $('.faqDetailOverlay').animate({
                          scrollTop: ($('.faqDetailOverlay').scrollTop() + $('.faqDetailCard.active').position().top) - 50
                        }, 500);
                      }

                    }, 1200);


                });


                $(this.getSelectors()).find('.faqDetailCard').on('click', function (e) {

                    var index = $(this).attr('index');

                    $('.faqDetailOverlay').find('.counter .index').text(("0" + (parseInt(index) + 1)).slice(-2));

                    $('.faqDetailOverlay').find('.faqDetailCard').removeClass('active');

                    $('.faqDetailOverlay').find('.faqDetailCard[index="'+index+'"]').addClass('active');

                    setTimeout(function(){
                      $('.expanderContent').animate({
                        scrollTop: ($('.expanderContent').scrollTop() + $('.faqDetailCard.active').position().top) - 50
                      }, 500);
                    }, 1200);


                });

                $(this.getSelectors()).find('.faqDetailCard .faqDetailCardAnswer a').on('click', function (e) {
                    $(module.getSelectors()).find('.faqNavClose').trigger('click');
                });

                // nav clicks
                $(this.getSelectors()).find('.expanderNav span').on('click', $.proxy(function (e) {
                    activeIndex = $('.faqDetailCard.active').attr('index');
                    nextIndex = parseInt(activeIndex) + 1;
                    prevIndex = parseInt(activeIndex) - 1;

                    if ($(e.target).hasClass('faqNavNext')) {
                        $('.faqDetailCard[index="'+nextIndex+'"]').trigger('click');
                        if (nextIndex == data.length) {
                            $('.faqDetailCard[index="0"]').trigger('click');
                        }
                    }
                    else if ($(e.target).hasClass('faqNavPrev')) {
                        $('.faqDetailCard[index="'+prevIndex+'"]').trigger('click');
                        if (activeIndex == 0) {
                            $('.faqDetailCard[index="'+gridCountIndex+'"]').trigger('click');
                        }
                    }
                }, this));

                // close click
                $(this.getSelectors()).find('.faqNavClose').on('click', $.proxy(function (e) {
                    e.preventDefault();

                    $('.expanderGrid').find('.qaCardInner').removeAttr('data-active');
                    $('.faqDetailOverlay').removeClass('showDetail');
                    $('body').removeClass('locked');
                    $('.navbarWrap').fadeIn();

                }, this));
            },
        }
    });
});

